import * as React from "react"
import useWindowSize from "utils/useWindowSize";

export type GlobalContent = {
  width: Number
}

const defaultValues = {
  width: 0
}

export const SiteContext = React.createContext<GlobalContent>(defaultValues)

export const SiteProvider = ({ children }:{children:any}) => {
  const [ width ] = useWindowSize();
  React.useEffect(() => {

  },[]);
  return (
    <SiteContext.Provider
      value={{
        ...defaultValues,
        width
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}